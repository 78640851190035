.flagModalBody{
    flex: none !important;
    height: 100% !important;
    padding: 0% !important;
    width: 100% !important;
    display: block;
    overflow-y: scroll;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ{
    max-height: 350px !important;
}

.countries{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 30px;
}

.oneCountry{
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: smaller;
    margin: 10px 0;
    width: 100%;
}

.flag{
    margin-right: 12px;
    width: 10%!important; 
    
}

#search-box{
    margin: 10px 0;
    width: 100%;
}