.container3 {
  display: flex;
  flex-direction: column;
  padding: 0 3%;
  position: relative;
  margin-top: -50px;
  height: -webkit-fill-available;
  align-items: center;
}

.questionsHeader {
  position: absolute;
  top: 0;
  width: 100%;
}

.svgContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.questionTop {
  z-index: -1;
  width: 100%;
  margin-top: -50px;
  overflow: hidden;
}

@media screen and (min-width:701px) {
  .questionTop {
    margin-top: -27%
  }
}

.container2 {
  display: flex;
  flex-direction: column;
  padding: 0 3%;
  position: relative;
  width: 100%;
}

.logos {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  margin: 130.6px 0 40px 0;
  padding: 0 12px 0 12px;
  width: 100%;
}

@media screen and (min-width:701px) {
  .logos {
    width: 573px;
  }

  .container2 {
    width: 573px;
    padding: 0;
  }
}

img {
  width: 35%;
}

.quest {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0 28px 80px 28px;
  border-radius: 24px;
  border-bottom: 5px solid #0085FF;
}

.questID {
  font-weight: 400;
  font-size: 13px;
  margin: 9% 0;
  z-index: 9;
  color: #702283;
  font-family: 'Mochiy Pop One', sans-serif !important;
  padding: 10px 0;
  display: flex;
  align-items: center;
  gap: 12px;
}

.questContent {
  font-weight: 600;
  font-size: 17px;
  font-family: 'Montserrat', sans-serif !important;
}

.options {
  margin-top: 14%;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  gap: 25px;
}

.inputs {
  display: flex;
  gap: 20px;
}

.button-principale {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  z-index: 9;
}

.loader {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
}

.circle {
  bottom: 0;
  height: 60px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
  height: 100px;
  animation: spin 1s infinite;
}

.arrow-left-style {
  width: 45px;
  height: 55px;
  padding-top: 20px !important;
}

.w-icon-home {
  width: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 10px;
  height: 10%
}

.text-error-question {
  padding-bottom: 4%;
  text-align: center;
}

.badge-question {
  position: absolute;
  right: 0px !important;
  margin-right: -10px;
  margin-top: -10px;
  top: 0px !important;
  height: 100px;
  width: 100px;
}

.text-lvl-1 {
  color: #8CBE22 !important;
}

.text-lvl-2 {
  color: #702283 !important;
}

.text-lvl-3 {
  color: #E52727 !important;
}

.text-lvl-4 {
  color: #009FE3 !important;
}

.text-lvl-5 {
  color: #FED501 !important;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}