.loader {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999;
}

.circle {
    bottom: 0;
    height: 60px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
    height: 100px;
    animation:
        spin 1s infinite;
}

.splashContainer {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.firstLogo {
    position: absolute;
    width: 50%;
    animation: Fade 1s ease-in-out, Disappear 1s forwards 3.5s;
}

.sponsors {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    position: absolute;
    transform: translateY(-200px);
    opacity: 0;
    animation: FadeIn 1s forwards 4.5s, goUp 0.5s forwards 5.5s;
    max-width: 689px;
}

@media screen and (min-width:701px) {
    .sponsors {
        height: 10%;
    }
}

.splash {
    opacity: 0;
    animation: FadeIn 1s forwards 7s;
}

.firstOne {
    margin-top: 50px;
    opacity: 0;
    animation: FadeIn 1s forwards 8s;
}

.ifef-logo {
    width: 150px !important;
    height: 81px !important;
}

.oif-logo {
    width: 175px !important;
    height: 80px !important;
}

.splashHeader {
    position: absolute;
    top: 0;
    opacity: 0;
    animation: FadeIn 0.5s forwards 5.5s;
    width: 100%;
}

.svgContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.splashTop {
    z-index: 9;
    width: 100%;
    margin-top: -50px
}


.yellowSquare {
    top: 10%;
    width: 100%;
    height: 0px;
    margin-top: -90px;
    animation: changeHeight 1s forwards 6s;
    background-color: #CAEDEB;
}

@media screen and (min-width:701px) {
    .splashTop {
        margin-top: -27%
    }
}

@media screen and (min-width:1350px) {
    .yellowSquare {
        margin-top: -250px;
    }
}

.downWave {
    width: 100%;
    margin-top: -80px;
}

@keyframes changeHeight {
    to {
        height: 568px;
    }
}

@keyframes Fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes Pop {
    to {
        width: 80%;
        transform: translateY(30px);
    }
}

@keyframes Back {
    to {
        width: 40%;
        transform: translateY(-200px);
    }
}

@keyframes Disappear {
    to {
        opacity: 0;
    }
}

@keyframes FadeIn {
    to {
        opacity: 1;
    }
}

@keyframes goUp {
    to {
        transform: translateY(-230px);
    }
}

@keyframes float {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-25px);
    }

    100% {
        transform: translateY(0px);
    }
}