.sharingModalBody {
    width: 100%;
    border-radius: 5px;
}

.sharingModalBody p {
    margin-bottom: 0rem;
    color: #009FE3;
}

.sharingModalBody h1 {
    font-weight: 400;
    font-family: "Mochiy Pop One", sans-serif;
    text-align: center;
    color: #000;
}