.sub-title {
  color: #702283;
  font-family: "Mochiy Pop One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 29px;
  text-align: center;
  margin: 20px 20px 40px 20px !important;
}

.text-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #3879da !important;
}

.text-style {
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.container-padding {
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.pointer {
  cursor: pointer;
}

.backArrow {
  cursor: pointer;
  width: 25px;
  height: 25px;
  position: absolute;
  margin-top: -20px;
  color: #47C8BF;
}