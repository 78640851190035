.progress-flex {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
  height: 250px;
  padding: 10px;
}

.border-div {
  position: relative;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 0px 25px 5px rgba(0, 127, 182, 0.08);
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: space-between;
}

.border-div::after {
  content: '';
  height: 15px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right,
      #8cbe22 0%,
      #702283 30%,
      #e51d26 50%,
      #009fe3 80%,
      #ffd500 100%);
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.text-progression {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #2d2c2d;
  font-style: normal;
  font-size: 12px;
  line-height: 24px;
  color: #2d2c2d;
  padding: 10px;
  margin-bottom: 10px;
}

.text-div-1 {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  margin-top: 5px;
}

.flex-div-image {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  width: 100%;
  margin: 0px 0 40px 0;
}

.flex-div-bienvenue {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.homePageBtn {
  width: 80% !important;
}

.jawHome {
  position: absolute;
  width: 70%;
  transform: translateY(5%);
}

.shareBtn {
  width: 50px !important;
  height: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: unset !important;
  color: #0085FF !important;
  box-shadow: 0px 0px 25px 5px rgba(0, 127, 182, 0.08);
  z-index: 12;
  border-radius: 50%;
}

.downBtns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.uploaded-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploaded-logo img {
  max-height: 150px;
  width: 98%;
}

@media screen and (min-width:701px) {
  .flex-div-image {
    width: 573px;
  }

  .flex-div-bienvenue {
    width: 573px;
  }
}