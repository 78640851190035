.display-scan-flex {
  display: flex !important;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.4);
  flex-direction: column !important;
}

.display-scan-flex>section>div {
  padding-top: 0 !important;
}

.display-scan-flex>section>div>video {
  position: inherit !important;
  width: 80% !important;
  margin: auto !important;
  border: 3px solid #0085FF;
  transform: rotate(-180deg);
}

.qr-code {
  height: 90%
}