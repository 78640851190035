.modalBtn {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 12px;
}

.homebtn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 8px;
  border: none;
  border-radius: 50px;
  width: 40%;
  height: 50px;
  z-index: 9;
}

.scanbtn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 8px;
  border: none;
  border-radius: 50px;
  width: 40%;
  height: 50px;
  background-color: #0085FF;
}

.homeicon path {
  stroke: #0085FF !important;
}

.homeicon {
  width: 20px;
  height: 20px;
}

.scanicon {
  width: 20px;
  height: 20px;
}

.checkContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 150px;
}

.modal-content::after {
  content: '';
  height: 6px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right,
      #8cbe22 0%,
      #702283 30%,
      #e51d26 50%,
      #009fe3 80%,
      #ffd500 100%);
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

.content {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 20px;
}

.jaw {
  position: absolute;
  transform: translateY(30px);
  animation: appear 0.5s forwards 0.5s;
  width: 0%;
}

.finalSubText {
  display: flex;
  flex-direction: column;
}

.border-div2 svg {
  position: absolute !important;
  width: 100% !important;
  margin-bottom: 20px;
}

@keyframes appear {
  to {
    width: 110%;
  }
}