.progress-bar-container {
  position: absolute;
  top: 55px;
  width: 100%;
  background-color: #caedeb;
  text-align: center;
  padding: 20px;
}

.progress-percentage {
  position: absolute;
  top: 3px;
  left: 46%;
  font-weight: 800;
  font-size: 15px;
}

.progress {
  position: relative;
  border-radius: 50px;
  height: 25px;
}

.default-bar {
  border-radius: 50px;
}

.progress-bar-20 {
  width: 10%;
  background-color: #8cbe22;
}

.progress-bar-40 {
  width: 10%;
  background-color: #702283;
}

.progress-bar-60 {
  width: 10%;
  background-color: #e51d26;
}

.progress-bar-80 {
  width: 10%;
  background-color: #009fe3;
}

.progress-bar-100 {
  width: 10%;
  background-color: #ffd500;
}