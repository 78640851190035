.title-register {
  font-family: 'Mochiy Pop One', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  padding-bottom: 18px !important;
  color: #702283;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.div-flex-register {
  display: flex;
  flex-direction: column;
}

.w-button {
  padding-top: 10%;
  padding-bottom: 20px;
  width: 100%;
  align-items: end;
}

.no-border {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  box-shadow: none;
  padding-top: 8px;
  border-bottom: 1px solid #f2f2f2 !important;
}

.align-me {
  display: grid;
  justify-items: flex-start;
}

.main-register {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: flex-start;
  margin: 0px 30px 20px 30px;
}

.registerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-red {
  color: red;
}

label {
  display: inline !important;
  margin-left: 4px;
}

.country {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.selectedCountry {
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-top: 15px;
  background-color: #efefef;
  height: 40px;
}

.arrow-down-style {
  width: 20px;
  height: 40px;
  padding-top: 4px !important;
  color: black;

}

.displayedCountry {
  width: 80%
}

.sexSelect {
  display: flex;
  width: 100%;
  align-items: center;
  height: 40px;
}

.sexSelect:focus {
  outline: none;
}

.text-warning {
  color: #b8b7b3 !important;
  font-size: 9px !important;
  font-weight: 400
}