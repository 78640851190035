.backgroundContainer{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
}

.backgroundHeader{
    width: 100%;
}

.yellowBackground{
    width: 100%;
    margin-top: -43px;
}

@media screen and (min-width: 501px) {
    .yellowBackground{
        margin-top: -26%;
    }
}

.questBack{
    position: absolute;
}

.questSplashTop,.questYellowSquare,.questDownWave{
    width: 100%;
}

.questYellowSquare{
    transform: translateY(-5%);
}

.questDownWave{
    transform: translateY(-70%);
}

@keyframes float {
    0%{
        transform: translateY(0px);
    }
    50%{
        transform: translateY(-25px);
    }
    100%{
        transform: translateY(0px);
    }
}