.main {
  display: flex;
  flex-direction: column;
  text-align: center !important;
  align-items: center !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  position: relative;
  justify-content: center;
  padding-bottom: 30px;
}

.title-principale {
  font-family: 'Mochiy Pop One', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px;
  line-height: 29px;
  margin: 20px 0 20px 0 !important;
  color: #702283;
}

.text-principale {
  color: #2d2c2d;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding: 10px;
}

.button-principale {
  background: #0085ff !important;
  border-radius: 50px !important;
  width: 100% !important;
  height: 60px !important;
  color: #fff !important;
  border-color: #0085ff !important;
  border-bottom-color: #0085ff !important;
  z-index: 9;
}

.flex-div-1 {
  height: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 40%;
}

@media screen and (min-width: 501px) {
  .flex-div-1 {
    margin-top: 20% !important;
  }
}

@media screen and (min-width: 701px) {
  .flex-div-1 {
    width: 573px;
  }
}

.div-height {
  height: 70%;
}

.oops {
  width: 50%;
}