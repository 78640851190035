.progressionValue {
    color: #2D2C2D;
    font-size: 24px;
}

.border-div svg {
    position: absolute !important;
    width: 50% !important;
}

#paths path {
    animation: rotation 5s ease-in-out infinite;
}

@keyframes rotation {
    0% {
        transform: rotateZ(0);
    }

    100% {
        transform: rotateZ(360deg);
    }
}